<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="templateData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching template data
      </h4>
    </b-alert>

    <template v-if="templateData">
      <b-row>
        <b-col
          cols="12"
          xl="9"
          lg="8"
          md="7"
        >
        <template-info-card 
          :template-data="templateData"
          :baseURL="baseURL" />
        </b-col>
      </b-row>
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  BRow, BCol, BAlert, BLink,
} from 'bootstrap-vue'
import TemplateStoreModule from '../storeModule'
import TemplateInfoCard from './InfoCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    // Local Components
    TemplateInfoCard,
  },
  data() {
    return {
      templateData: undefined,
      TEMPLATE_APP_STORE_MODULE_NAME: 'app-template',
      baseURL : process.env.VUE_APP_BASE_API
    }
  },
  mounted() {
    // Register module
    if (!store.hasModule(this.TEMPLATE_APP_STORE_MODULE_NAME)) store.registerModule(this.TEMPLATE_APP_STORE_MODULE_NAME, TemplateStoreModule)

    this.fetchTemplate()
  },
  unmounted() {
    // UnRegister on leave
    if (store.hasModule(this.TEMPLATE_APP_STORE_MODULE_NAME)) store.unregisterModule(this.TEMPLATE_APP_STORE_MODULE_NAME)
  },
  methods: {
    fetchTemplate() {
      store.dispatch('app-template/fetchTemplate', { id: router.currentRoute.params.id })
      .then(response => { 
        this.templateData = response.data 
      })
      .catch(error => {
        if (error.response.status === 404) {
          console.info('404', this.templateData)
          templateData = undefined
        }
      })
    },
  }
}
</script>

<style>

</style>
