<template>
  <b-card>
    <b-row>
      <!-- Right Col: Table -->
      <b-col
        cols="12"
        xl="6"
      >
        <table class="mt-2 mt-xl-0 w-100">
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="UserIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('template.name') }}</span>
            </th>
            <td class="pb-50">
              {{ templateData.name }}
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="CheckIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('template.status') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <b-badge
                pill
                :variant="`light-${resolveTemplateStatusVariant(templateData.status)}`"
                class="text-capitalize"
              >
                {{ resolveTemplateStatusMessage(templateData.status) }}
              </b-badge>
            </td>
          </tr>

          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('template.header') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <a
                :href="`${baseURL}/api/v1/core/template/${templateData.id}/header`"
                target="_blank"
              > {{ templateData.header }}</a>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('template.body') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <a
                :href="`${baseURL}/api/v1/core/template/${templateData.id}/body`"
                target="_blank"
              > {{ templateData.body }}</a>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('template.footer') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              <a
                :href="`${baseURL}/api/v1/core/template/${templateData.id}/footer`"
                target="_blank"
              > {{ templateData.footer }}</a>
            </td>
          </tr>
          <tr>
            <th class="pb-50">
              <feather-icon
                icon="BookOpenIcon"
                class="mr-75"
              />
              <span class="font-weight-bold">{{ $t('template.description') }}</span>
            </th>
            <td class="pb-50 text-capitalize">
              {{ templateData.description }}
            </td>
          </tr>
        </table>
      </b-col>
    </b-row>
    <hr>
    <!-- Action Buttons -->
    <b-row>
      <b-col>
        <router-link :to="{name: 'apps-templates-list'}">
          <b-button
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
          >
            {{ $t('actions.back') }}
          </b-button>
        </router-link>
        <b-button
          :to="{ name: 'apps-templates-edit', params: { id: templateData.id } }"
          variant="primary"
        >
          {{ $t('actions.edit') }}
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BBadge,
} from 'bootstrap-vue'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar, BBadge,
  },
  props: {
    templateData: {
      type: Object,
      required: true,
    },
    baseURL: {
      type: String,
      required: true,
    },
  },
  methods: {
    resolveTemplateStatusVariant(status) {
      if (status == 0) return 'warning'
      return 'success'
    },
    resolveTemplateStatusMessage(status) {
      if (status == 0) return this.$t('template.statusValue.inactive')
      return this.$t('template.statusValue.active')
    },
  },
}
</script>

<style>

</style>
